import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './cross-achievement.module.css';
import * as images from './images';

const names = [
  'За широкую экспансию',
  'За интенсивное развитие',
  'За охрану природы',
  'За скаутские успехи',
  'За доминирование',
];

export const CrossAchievement = ({
  id,
  name: originalName,
  teamLabel,
  value,
}) => {
  const name = originalName || names[id - 1];
  const { t } = useTranslation();
  return (
    <div className={styles.achievement}>
      <img
        className={styles.img}
        src={images[`achievement${id}`]}
        alt={id}
        title={name}
      />
      <span className={styles.label}>{teamLabel}</span>
      <span className={styles.name}>{name}</span>
      {value > 0 && (
        <span className={styles.name}>{t('game.stats.points', { value })}</span>
      )}
    </div>
  );
};

CrossAchievement.propTypes = {
  id: PropTypes.number.isRequired,
  teamLabel: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  name: PropTypes.string,
};

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';

import styles from './statistic-modal.module.css';
import { Modal } from '../../components/modal/modal';
import { useAppStore } from '../../store/app';
import { getRoundName } from '../../helpers';
import { BuildingsInfo } from '../../components/buildings-info/buildings-info';
import { ResultsFull } from '../../components/results/results-full';
import { Achievement } from '../../components/achievement/achievement';
import { AchievementMore } from '../../components/achievement/achievement-more';
import { AchievementFull } from '../../components/achievement/achievement-full';

export const StatisticModal = observer(() => {
  const {
    showModal,
    closeModal,
    log,
    round,
    results,
    actives,
    achievements,
    teamName,
    teamColor,
    teamColorName,
    areAchievementsAvailable,
  } = useAppStore();
  const [showAchievements, setShowAchievements] = useState(false);
  const { t } = useTranslation();
  const activeAchievements = achievements.filter(item => item.is_active);
  const handleCloseModal = () => {
    setShowAchievements(false);
    closeModal();
  };
  return (
    <Modal
      dialogClassName={styles.modal}
      show={showModal === 'stats'}
      onHide={handleCloseModal}
      size="lg"
      withDecorations
    >
      <Modal.Title icon="stat">
        {t(`game.stats.${showAchievements ? 'achievements' : 'title'}`)}
        {showAchievements && (
          <button
            type="button"
            className={styles.backBtn}
            onClick={() => setShowAchievements(false)}
          >
            {t('game.stats.goBack')}
          </button>
        )}
      </Modal.Title>
      {showAchievements ? (
        <div>
          <div>
            Эти награды вы получаете за захват контрольных точек в центре карты.
            Чтобы получить награду достаточно захватить точку один раз — если вы
            потеряете точку, достижение все равно останется у вас. В течение
            игры на этих точках будут появляться новые награды, так что следите
            за новостями
          </div>
          <br />
          <div className={styles.allAchievementsContainer}>
            <Scrollbars style={{ width: '100%', height: '500px' }}>
              <div className={styles.allAchievements}>
                {achievements.map(item => (
                  <div key={item.id} className={styles.item}>
                    <AchievementFull {...item} />
                  </div>
                ))}
              </div>
            </Scrollbars>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.information}>
            {t('game.stats.teamInfo', { name: teamName })}{' '}
            <span
              className={styles.color}
              style={{ backgroundColor: teamColor }}
            >
              {teamColorName}
            </span>
          </div>
          <div className={styles.modalContainer}>
            <div className={styles.left}>
              <div className={styles.title}>{t('game.stats.rating')}</div>
              <ResultsFull data={results} />
            </div>
            <div className={styles.right}>
              <div className={styles.rightInfo}>
                <div className={styles.actives}>
                  <div className={cn(styles.titleBlock, styles.title)}>
                    {t('game.stats.actives')}
                  </div>
                  <div className={styles.activesTable}>
                    <BuildingsInfo
                      tableData={actives.actives}
                      summary={actives.activesSum}
                      additionalSum={actives.additionalSum}
                    />
                  </div>
                </div>
                <div className={styles.log}>
                  <div className={cn(styles.titleBlock, styles.title)}>
                    {t('game.stats.results', {
                      round: getRoundName(round - 1),
                    })}
                  </div>
                  <Scrollbars
                    style={{ width: '100%', height: '100%' }}
                    height={300}
                  >
                    <ul>
                      {log.map(item => (
                        <li key={item} className={styles.logItem}>
                          {item}
                        </li>
                      ))}
                    </ul>
                  </Scrollbars>
                </div>
              </div>
              {achievements.length > 0 && (
                <div className={styles.achievements}>
                  {activeAchievements
                    .filter(item => item.is_active)
                    .slice(0, 4)
                    .map(item => (
                      <Achievement
                        key={item.id}
                        id={item.id}
                        name={item.name}
                      />
                    ))}
                  {areAchievementsAvailable && (
                    <AchievementMore
                      onClick={() => setShowAchievements(true)}
                      count={activeAchievements.length}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </Modal>
  );
});

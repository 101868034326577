/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';

import { Table } from 'react-bootstrap';
import styles from './help.module.css';
import { Modal } from '../../components/modal/modal';
import { useAppStore } from '../../store/app';
import { Node } from '../../components/map/node/node';

export const HelpModal = observer(() => {
  const { showModal, closeModal } = useAppStore();
  const { t } = useTranslation();
  const renderView = ({ ...props }) => (
    <div className={styles.view} {...props} />
  );
  return (
    <Modal
      show={showModal === 'help'}
      onHide={closeModal}
      size="lg"
      dialogClassName={styles.modal}
      withDecorations
    >
      <Modal.Title icon="help">{t('game.help.title')}</Modal.Title>
      <div className={styles.block}>
        <Scrollbars
          style={{ width: '100%' }}
          autoHeight
          autoHeightMin={300}
          autoHeightMax={500}
          renderView={renderView}
        >
          <p>
            The main goal of the game is to earn as many victory points as
            possible by conquering territory and erecting snow fortresses.
          </p>
          <p>
            The main &laquo;currency&raquo; in the game is snow. You can make
            snowballs from the snow to attack neighboring fortresses. Also, snow
            can be used as a building material to strengthen your fortresses.
          </p>
          <p>
            <strong>How can you earn points?</strong>
          </p>
          <p>You start the game at one of the initial areas, and you can: </p>
          <ol>
            <li>
              <strong>Capture new areas</strong> on the map. The more areas you
              control, the more points you earn.
            </li>

            <li>
              <strong>Upgrade fortresses</strong> in already captured areas. The
              higher the level of your snow fortresses, the more snow they bring
              you, and the more difficult it will be for opponents to capture
              them.
            </li>

            <li>
              <strong>Win challenge prizes.</strong> In the game you will
              receive additional points for various achievements. You can read
              more about this below.
            </li>
          </ol>
          <p>
            <strong>How to capture a new area?</strong>
          </p>
          <ol>
            <li>
              <span>On the game map, click on one of your fortresses</span>
            </li>
            <li>
              <span>
                In the drop-down window find the option &quot;Capture area&quot;
              </span>
            </li>
            <li>
              <span>
                In the drop-down list, select the neighboring area that you plan
                to attack
              </span>
            </li>
            <li>
              <span>
                Choose the number of snowballs that you are going to spend on
                the attack
              </span>
            </li>
            <li>
              <span>
                Click on the blue &quot;+&quot; button opposite the selected
                action
              </span>
            </li>
          </ol>
          <p>
            At any time before the end of the round, you can cancel this
            decision by clicking on the red &quot;-&quot; button opposite the
            corresponding action in the &quot;Decisions taken&quot; section.
          </p>

          <p>
            <strong>How to upgrade a fortress?</strong>
          </p>
          <ol>
            <li>
              <span>On the game map, click on one of your fortresses</span>
            </li>
            <li>
              <span>
                In the drop-down window, find the &quot;Upgrade the
                fortress&quot; option and click on the blue &quot;+&quot; button
                opposite the selected action
              </span>
            </li>
          </ol>
          <p>
            If you have enough snow to perform this action, it will be accepted
            for execution. At any time before the end of the round, you can
            cancel this decision by clicking on the red &quot;-&quot; button
            opposite the corresponding action in the &quot;Decisions taken&quot;
            section.
          </p>

          <p>
            <strong>Where can I see the statistics?</strong>
          </p>
          <p>
            In the &quot;My team&quot; tab, you can see the rating of the teams,
            find out the results of your actions in the last round, get data on
            the captured points, find out which fortresses are at what level of
            development and how many victory points they bring you.
          </p>

          <p>
            <strong>What else can I do in the game?</strong>
          </p>
          <ul>
            <li>
              <strong>Redistribute snow stocks</strong>. You can distribute snow
              supplies between your fortresses. Sent supplies will move to a
              different location at the start of the next round.
            </li>

            <li>
              <strong>Chat with other teams</strong>. In the &quot;Chats&quot;
              tab, you can enter into a dialogue with any other team to exchange
              news or form an alliance. For example, to jointly attack the third
              team, or vice versa, to agree on non-aggression against each
              other.
            </li>
          </ul>

          <p>
            <strong>Game coefficients</strong>
          </p>

          <p>
            Note! Fortresses outside the center of the map bring more snow, but
            less victory points, and forts in the center of the map, less snow,
            but more points! Control the fortresses outside the castle and use
            the snow you get to capture the fortresses inside it!
          </p>

          <p>
            <strong>Common fortresses</strong>
          </p>

          <Table>
            <tr>
              <td>
                <strong>Level 1</strong>
              </td>
              <td>
                <strong>Level 2</strong>
              </td>
              <td>
                <strong>Level 3</strong>
              </td>
              <td>
                <strong>Level 4</strong>
              </td>
              <td>
                <strong>Level 5</strong>
              </td>
            </tr>
            <tr className={styles.levels}>
              <td>
                <Node level={1} />
              </td>
              <td>
                <Node level={2} />
              </td>
              <td>
                <Node level={3} />
              </td>
              <td>
                <Node level={4} />
              </td>
              <td>
                <Node level={5} />
              </td>
            </tr>
            <tr>
              <td colSpan="5">
                <strong>How much snow does it bring</strong>
              </td>
            </tr>
            <tr>
              <td>50</td>
              <td>150</td>
              <td>300</td>
              <td>500</td>
              <td>800</td>
            </tr>
            <tr>
              <td colSpan="5">
                <strong>How much snow does it take to capture</strong>
              </td>
            </tr>
            <tr>
              <td>50</td>
              <td>150</td>
              <td>300</td>
              <td>500</td>
              <td>800</td>
            </tr>
            <tr>
              <td colSpan="5">
                <strong>
                  How much snow does it take to upgrade to the next level
                </strong>
              </td>
            </tr>
            <tr>
              <td>50</td>
              <td>200</td>
              <td>500</td>
              <td>1000</td>
              <td>нет</td>
            </tr>
            <tr>
              <td colSpan="5">
                <strong>How many victory points does it bring</strong>
              </td>
            </tr>
            <tr>
              <td>1</td>
              <td>2</td>
              <td>5</td>
              <td>10</td>
              <td>20</td>
            </tr>
          </Table>

          <p>
            <strong>Fortresses in the center of the map</strong>
          </p>

          <Table>
            <tr>
              <td>
                <strong>Level 1</strong>
              </td>
              <td>
                <strong>Level 2</strong>
              </td>
              <td>
                <strong>Level 3</strong>
              </td>
              <td>
                <strong>Level 4</strong>
              </td>
              <td>
                <strong>Level 5</strong>
              </td>
            </tr>
            <tr className={styles.levels}>
              <td>
                <Node level={1} isControlPoint />
              </td>
              <td>
                <Node level={2} isControlPoint />
              </td>
              <td>
                <Node level={3} isControlPoint />
              </td>
              <td>
                <Node level={4} isControlPoint />
              </td>
              <td>
                <Node level={5} isControlPoint />
              </td>
            </tr>
            <tr>
              <td colSpan="5">
                <strong>How much snow does it bring</strong>
              </td>
            </tr>
            <tr>
              <td>30</td>
              <td>70</td>
              <td>120</td>
              <td>200</td>
              <td>300</td>
            </tr>
            <tr>
              <td colSpan="5">
                <strong>How much snow does it take to capture</strong>
              </td>
            </tr>
            <tr>
              <td>50</td>
              <td>150</td>
              <td>300</td>
              <td>500</td>
              <td>800</td>
            </tr>
            <tr>
              <td colSpan="5">
                <strong>
                  How much snow does it take to upgrade to the next level
                </strong>
              </td>
            </tr>
            <tr>
              <td>50</td>
              <td>200</td>
              <td>500</td>
              <td>1000</td>
              <td>нет</td>
            </tr>
            <tr>
              <td colSpan="5">
                <strong>How many victory points does it bring</strong>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>5</td>
              <td>12</td>
              <td>25</td>
              <td>50</td>
            </tr>
          </Table>

          <p>
            <strong>Challenge prizes</strong>
          </p>
          <p>
            There are several chappenge prizes in the game. Each of them is
            worth additional Victory Points.
          </p>
          <ul>
            <li>
              <span>For a wide expansion</span>
              <span>
                &nbsp;(the largest number of captured fortresses outside the
                center of the map) - 25 points
              </span>
            </li>
            <li>
              <span>For dominance</span>
              <span>
                &nbsp;(the largest number of captured fortresses in the center
                of the map) - 25 points
              </span>
            </li>
            <li>
              <span>For intensive development</span>
              <span>
                &nbsp;(the largest number of fortresses of the 5th level) - 25
                points
              </span>
            </li>
          </ul>
          <p>Good luck!</p>
        </Scrollbars>
      </div>
    </Modal>
  );
});

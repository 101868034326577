import React from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';

import styles from './next-round-modal.module.css';
import newsStyles from '../news-modal/news-modal.module.css';
import { Modal } from '../../components/modal/modal';
import { useAppStore } from '../../store/app';
import { ResultsFull } from '../../components/results/results-full';
import { CrossAchievement } from '../../components/cross-achievement/cross-achievement';

export const NextRoundModal = observer(() => {
  const {
    showModal,
    closeModal,
    results,
    newsData,
    getTeamColorById,
    crossAchievements,
  } = useAppStore();
  const { t } = useTranslation();
  const { insides = [] } = newsData;
  return (
    <Modal show={showModal === 'next-month'} onHide={closeModal} size="lg">
      <Modal.Title icon="stat">{t('game.nextRound.title')}</Modal.Title>
      <div className={styles.row}>
        <div className={styles.chart}>
          <Modal.Subtitle>{t('game.nextRound.rating')}</Modal.Subtitle>
          <ResultsFull
            data={results.map(({ id, value, percentage }) => ({
              id,
              value,
              color: getTeamColorById(id),
              percentage,
            }))}
            highlightTop
          />
        </div>
        <div className={styles.achievements}>
          <div className={styles.title}>
            <Modal.Subtitle>{t('game.nextRound.achievements')}</Modal.Subtitle>
          </div>
          <div className={styles.crossAchievements}>
            {crossAchievements.map(item => (
              <div key={item.id} className={styles.item}>
                <CrossAchievement
                  id={item.id}
                  teamLabel={item.teamLabel}
                  value={item.value}
                  name={item.name}
                />
              </div>
            ))}
          </div>

          {insides && insides.length > 0 && (
            <>
              <Modal.Subtitle icon="insides">
                {t('game.insides.title')}
              </Modal.Subtitle>
              <div className={cn(newsStyles.newsBlock, styles.news)}>
                <Scrollbars
                  style={{ width: '100%' }}
                  autoHeight
                  autoHeightMin={100}
                  autoHeightMax={300}
                >
                  <div className={newsStyles.newsList}>
                    {insides.slice(0, 2).map(item => (
                      <div key={item} className={newsStyles.newsItem}>
                        {item}
                      </div>
                    ))}
                  </div>
                </Scrollbars>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
});

export const saveName = name => localStorage.setItem('quiz_heroes_name', name);

export const loadName = () => localStorage.getItem('quiz_heroes_name');

export const setStorageItem = (key, value) => {
  return new Promise((resolve, reject) => {
    try {
      localStorage.setItem(key, value);
      // eslint-disable-next-line no-empty
    } catch (e) {
      reject(new Error('Не удалось сохранить сессию'));
    }
  });
};

export const getStorageItem = key => {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    return null;
  }
};

export const LANGUAGE_KEY = '_quiz-heroes-app-language';

const getNavigatorLanguage = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  }

  return (
    navigator.userLanguage ||
    navigator.language ||
    navigator.browserLanguage ||
    'en'
  );
};

export const getDefaultLanguage = () =>
  getStorageItem(LANGUAGE_KEY) || getNavigatorLanguage() || 'ru';

export const isEnvDevelopment = () => process.env.NODE_ENV === 'development';

const roundNames = [
  'saturday',
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
];

// export const getRoundNameNumber = round => {
//   const index = round - 1;
//   return index < 0 ? roundNames.length + index : index;
// };

// TODO: add locale names
export const getRoundName = round => {
  const index = round - 1;
  return roundNames[index < 0 ? roundNames.length + index : index];
};

export const formatCurrency = value =>
  Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    currencyDisplay: 'code',
    minimumFractionDigits: 0,
  })
    .format(value)
    .replace('RUB', '');

export const parseCurrency = value => {
  return value.replace(' р.');
};

export const hexToRgb = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16) / 255,
        g: parseInt(result[2], 16) / 255,
        b: parseInt(result[3], 16) / 255,
      }
    : null;
};

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import AuthForm from '../../components/auth-form/auth-form';
import RestoreSession from '../../components/restore-session/restore-session';
import Card from '../../components/card/card';
import classes from './auth.module.css';
import { useAppStore } from '../../store/app';
import line from '../../assets/line-small.svg';
import logo from '../../assets/logo.png';

function Auth() {
  const { connect, restoreSession, savedSession } = useAppStore();
  const { t } = useTranslation();
  return (
    <Container fluid>
      <Row className="justify-content-md-center">
        <Col>
          <div className={classes.withDecorations}>
            <Card className={classes.card} body>
              <div className={classes.logo}>
                <img
                  src={logo}
                  alt={t('game.name')}
                  className={classes.logoImage}
                />
              </div>
              <div className={classes.gameName}>{t('game.name')}</div>
              <div className={classes.gameDescr}>
                Command tactical
                <br />
                online game <img src={line} alt="game" />
              </div>
              <div className={classes.greetengs}>{t('game.auth.welcome')}</div>
              <div className={classes.text}>{t('game.auth.welcomeText')}</div>
              <AuthForm submit={connect} />
              {savedSession && (
                <RestoreSession
                  userName={savedSession}
                  submit={restoreSession}
                />
              )}
              <div className={classes.mobileDisclamer}>
                {t('game.auth.mobileDisclaimer')}
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Auth;

import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Button } from 'react-bootstrap';
import styles from './welcome-modal.module.css';
import { Modal } from '../../components/modal/modal';
import { useAppStore } from '../../store/app';
import logo from '../../assets/logo.png';
import line from '../../assets/line-small.svg';

export const WelcomeModal = observer(() => {
  const {
    showModal,
    closeModal,
    teamColor,
    teamColorName,
    teamName,
    teamStartNode,
  } = useAppStore();
  const { t } = useTranslation();
  return (
    <Modal
      show={showModal === 'welcome'}
      onHide={closeModal}
      size="lg"
      closable={false}
      dialogClassName={styles.modal}
      withDecorations
    >
      <div className={styles.modalContainer}>
        <div className={styles.logo}>
          <img src={logo} alt={t('game.name')} className={styles.logoImage} />
        </div>
        <div className={styles.gameName}>{t('game.name')}</div>
        <div className={styles.gameDescr}>
          Command tactical
          <br />
          game <img src={line} alt="game" />
        </div>
        <div className={styles.title}>Welcome to the game!</div>
        <div className={styles.description}>
          Your team&apos;s color on the playing field is —{' '}
          <span
            className={styles.teamColor}
            style={{
              backgroundColor: teamColor,
            }}
          >
            {teamColorName}
          </span>
          <br />
          You play as a team <strong>{teamName}</strong>
          <br />
          Your starting fortress: <strong>{teamStartNode.name}</strong>
          <br />
          <br />
          Your task is to score as many points as possible to become the best
          team. To do this, you can:
          <ul className={styles.list}>
            <li>capture snow fortresses</li>
            <li>develop captured fortresses</li>
            <li>
              get extra points for capturing and holding fortresses in center of
              the field
            </li>
          </ul>
        </div>
        <Button className={styles.button} onClick={closeModal}>
          Start the game
        </Button>
      </div>
    </Modal>
  );
});

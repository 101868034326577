import React from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './final-modal.module.css';
import { Modal } from '../../components/modal/modal';
import { useAppStore } from '../../store/app';
import { ResultsFull } from '../../components/results/results-full';
import firstPlace from '../../assets/final-1.png';
import secondPlace from '../../assets/final-2.png';
import thirdPlace from '../../assets/final-3.png';
import { CrossAchievement } from '../../components/cross-achievement/cross-achievement';

export const FinalModal = observer(() => {
  const {
    showModal,
    closeModal,
    results,
    getTeamColorById,
    getTeamNameById,
    crossAchievements,
  } = useAppStore();
  const { t } = useTranslation();
  if (!results.length) {
    return null;
  }

  return (
    <Modal
      show={showModal === 'final'}
      onHide={closeModal}
      size="lg"
      closable={false}
    >
      <div className={styles.row}>
        <div className={styles.chart}>
          <div className={styles.title}>{t('game.nextRound.rating')}</div>
          <ResultsFull
            data={results.map(({ id, value, percentage }) => ({
              id,
              value,
              color: getTeamColorById(id),
              percentage,
            }))}
            highlightTop
          />
        </div>
        <div className={styles.achievements}>
          <div className={styles.places}>
            <div className={cn(styles.placesItem, styles.second)}>
              <span className={styles.number}>2</span>
              <img src={secondPlace} alt="second place" />
              <span className={styles.name}>
                {getTeamNameById(results[1].id)}
              </span>
            </div>
            <div className={cn(styles.placesItem, styles.first)}>
              <span className={styles.number}>1</span>
              <img src={firstPlace} alt="first place" />
              <span className={styles.name}>
                {getTeamNameById(results[0].id)}
              </span>
            </div>
            <div className={cn(styles.placesItem, styles.third)}>
              <span className={styles.number}>3</span>
              <img src={thirdPlace} alt="third place" />
              <span className={styles.name}>
                {getTeamNameById(results[2].id)}
              </span>
            </div>
          </div>
          <div className={styles.crossAchievements}>
            {crossAchievements.map(item => (
              <div key={item.id} className={styles.item}>
                <CrossAchievement
                  id={item.id}
                  teamLabel={item.teamLabel}
                  name={item.name}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
});
